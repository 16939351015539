import React, {useEffect, useState} from 'react'
import Link from 'next/link'
import {getBattleStatus, BattleStatuses} from '@cssbattle/shared/battles'
import {CountDown} from 'src/CountDown'
import {BattleStatus} from 'src/BattleStatus'
import {Box, Separator, AvatarImage} from 'src/Common'
import {UpcomingBanner} from 'src/UpcomingBanner'
import {getBattleLeaderboard, NEXT_API} from 'src/util/db'
import {timeSince} from 'src/utils'
import styles from 'src/BattleTile.module.css'
import {Panel} from '@components/Panel'
import {HStack, VStack} from '@components/Stack'
import {BattleModeInfoPill} from './BattleModeInfoPill'
import {Text} from '@components/Text'
import {TvGlitch} from '@components/TvGlitch'

export function BattleTile({battle, fullWidth}) {
  const battleStatus = getBattleStatus(battle)

  const [topPlayers, setTopPlayers] = useState([])

  useEffect(() => {
    if (battleStatus === BattleStatuses.FINISHED) {
      fetch(`${NEXT_API}/battleLeaderboard?battleId=${battle.id}`).then(
        async (response) => {
          const data = await response.json()
          setTopPlayers(data)
        }
      )
    } else {
      getBattleLeaderboard({
        battleId: battle.id,
        numberOfPlayers: 3,
        includePlayedCount: false,
      }).then((leaderboard) => {
        setTopPlayers(leaderboard)
      })
    }
  }, [battle.id])

  return (
    <Panel
      // glowing={battleStatus === BattleStatuses.ONGOING}
      fullWidth={fullWidth}
    >
      <HStack gap="3rem" align="stretch" responsive>
        <div className={styles.BattleTile_info}>
          <VStack
            gap="1rem"
            align="flex-start"
            justify="space-between"
            fullHeight
          >
            <VStack gap="1.25rem" align="flex-start">
              <HStack gap="0.5rem">
                {/* <div className="pill">#{battle.id}</div> */}
                <BattleStatus status={battleStatus} />
                <BattleModeInfoPill gameMode={battle.gameMode} minimal={true} />
              </HStack>

              <Link href={`/battle/${battle.id}`}>
                <a className="link  link--no-underline">
                  <Text size={2} weight="600" mono={false} appearance="primary">
                    Battle #{battle.id} - {battle.name}
                  </Text>
                </a>
              </Link>

              <Text
                mono={false}
                weight={500}
                appearance="secondary"
                size={1}
                letterSpacing="0.3px"
              >
                {battle.description}
              </Text>

              <Separator />

              <div className={styles.BattleTile_countdown}>
                {battleStatus === BattleStatuses.FINISHED && (
                  <time dateTime={battle.endDate}>
                    <Text
                      mono={false}
                      weight={500}
                      appearance="tertiary"
                      size={1}
                      letterSpacing="0.3px"
                    >
                      Finished {timeSince(battle.endDate)} ago
                    </Text>
                  </time>
                )}
                {battleStatus === BattleStatuses.UPCOMING && (
                  <VStack gap="0.5rem" align="flex-start">
                    <Text
                      mono={false}
                      weight={500}
                      appearance="tertiary"
                      size={1}
                      letterSpacing="0.3px"
                    >
                      Starts in
                    </Text>

                    <CountDown
                      date={battle.startDate}
                      suffix=""
                      showUnits={true}
                      size={0.1}
                      showEmoji={false}
                      minimal={true}
                    />
                  </VStack>
                )}
                {battleStatus === BattleStatuses.ONGOING && (
                  <HStack gap="0.35rem" align="flex-start">
                    <Text
                      mono={false}
                      weight={500}
                      appearance="tertiary"
                      size={1}
                      letterSpacing="0.3px"
                    >
                      Ends in
                    </Text>
                    <CountDown
                      date={battle.endDate}
                      suffix=""
                      showUnits={true}
                      size={0.1}
                      showEmoji={false}
                      minimal={true}
                    />
                  </HStack>
                )}
              </div>
            </VStack>

            <HStack
              gap="0.5rem"
              align="stretch"
              justify="stretch"
              fullWidth
              wrap
            >
              <Link href={`/leaderboard/battle/${battle.id}`}>
                <a className="button">
                  <HStack gap="8px">
                    <span>Leaders</span>

                    <div
                      className="user-stack"
                      style={{
                        '--avatar-size': '24px',
                        '--stroke-width': '3px',
                        '--avatar-spacing': '-6px',
                        pointerEvents: 'none',
                      }}
                    >
                      <HStack gap="0">
                        {topPlayers.map((player, index) => (
                          <Box
                            className="user-stack__image-container"
                            key={player.id}
                          >
                            <AvatarImage
                              size="24"
                              userId={player.id}
                              url={player.playerProfile.avatar}
                              username={player.playerProfile.username}
                              useCdnUrl={true}
                            />
                          </Box>
                        ))}
                      </HStack>
                    </div>
                  </HStack>
                </a>
              </Link>

              <Link href={`/battle/${battle.id}`}>
                <a className="button button--primary" style={{flexGrow: 1}}>
                  Play
                </a>
              </Link>
            </HStack>
          </VStack>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(22%, 1fr))',
            gridTemplateRows: 'max-content',
            gap: '1rem',
            width: '100%',
          }}
        >
          {battle.levelIds.map((levelId, index) => {
            return levelId === 0 ? (
              <div className={styles.BattleTile_target} key={index}>
                <BattleTileImage battle={battle} levelId={levelId} />
              </div>
            ) : (
              <Link href={`/play/${levelId}`} key={index}>
                <a className={styles.BattleTile_target}>
                  <BattleTileImage battle={battle} levelId={levelId} />
                </a>
              </Link>
            )
          })}
        </div>
      </HStack>
    </Panel>
  )
}

const BattleTileImage = ({battle, levelId}) => {
  return (
    <>
      {levelId === 0 ? (
        <TvGlitch opacity="0.01">
          <img src="/images/lock.svg" height="50" style={{opacity: 0.75}} />
        </TvGlitch>
      ) : (
        <img
          loading="lazy"
          src={`/targets/${levelId}.png`}
          srcSet={`/targets/${levelId}@2x.png 2x`}
          alt={`Target #${levelId}`}
          width="400"
          height="300"
        />
      )}
      <div className={`target-top-info ${styles.BattleTile_target_info}`}>
        {levelId ? <p className="target-top-info__pill">#{levelId}</p> : null}
        {battle.openLevelIds && battle.openLevelIds.includes(levelId) ? (
          <p className="target-top-info__pill">
            <img alt="" src="/images/open-book.svg" />
          </p>
        ) : null}
      </div>
    </>
  )
}
