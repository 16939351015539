import {useRef, useState, useCallback} from 'react'

export const Ticker = ({items}) => {
  // issue: there was a jerk issue with ticker when we were moving each element from start to end of list, to avoid that, we just made the list longer instead of removing and adding the elements at the back of list, hence used --> [...items, ...items])
  // hack solution: this just makes the list big, so if someone stays on screen for a very long time, there will be a jerk just when the list ends and the animation starts again (basically jerk is delayed to once in 40 records, making it very less likely to be noticed)
  const [currentItems, setCurrentItems] = useState([...items, ...items])
  const wrapperRef = useRef()
  const animationRef = useRef(null)

  const handleRefUpdate = useCallback(
    (node) => {
      if (node !== null && currentItems?.length > 0) {
        wrapperRef.current = node

        const distance = wrapperRef.current.scrollWidth
        const duration = Math.round(distance / 60) * 1000 // in milliseconds

        // cancel any existing animation
        if (animationRef?.current) {
          animationRef.current.cancel()
        }

        animationRef.current = wrapperRef.current.animate(
          [
            {transform: 'translateX(0)'},
            {transform: `translateX(${0 - distance}px)`},
          ],
          {
            duration: duration,
            iterations: Infinity,
            easing: 'linear',
          }
        )
      }
    },
    [currentItems]
  )

  return (
    <div className="ticker-wrapper">
      <div
        ref={handleRefUpdate}
        onMouseEnter={() => animationRef.current?.pause()}
        onMouseLeave={() => animationRef.current?.play()}
      >
        {currentItems?.map((obj, index) => (
          <Element item={obj} key={index} />
        ))}
      </div>
    </div>
  )
}

const Element = ({item}) => {
  return <div className="ticker-element">{item}</div>
}
