import React, {useEffect} from 'react'
import {HStack} from './Stack'

function mapKeyName(key) {
  if (key === 'Control') return 'ctrl'
  return key.toLowerCase()
}

const KeyCombo = ({value}) => {
  const keys = value && value.split('+')
  const [states, setStates] = React.useState([])

  useEffect(() => {
    // listen on keydown and detect if key being pressed is in keys.
    function handleKeyDown(e) {
      const key = mapKeyName(e.key)
      if (keys.includes(key) && !states.includes(key)) {
        states.push(key)
        setStates([...states])
      }
    }

    function handleKeyUp(e) {
      const key = mapKeyName(e.key)
      if (keys.includes(key) && states.includes(key)) {
        states.splice(states.indexOf(key), 1)
        setStates([...states])
      }
    }
    document.body.addEventListener('keydown', handleKeyDown, {capture: true})
    document.body.addEventListener('keyup', handleKeyUp, {capture: true})

    return () => {
      removeEventListener('keydown', handleKeyDown)
      removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const numKeysPressed = states.length

  return (
    <HStack gap="0.25rem">
      {keys &&
        keys.map((key, index) => (
          <p
            className={`pill  pill--key ${
              states.includes(key) ? 'pill--key-pressed' : ''
            }`}
            key={index}
          >
            {key}
          </p>
        ))}
    </HStack>
  )
}

export {KeyCombo}
