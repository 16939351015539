import React, {useEffect, useState} from 'react'
import Link from 'next/link'
import {getActivityStream} from 'src/util/db.js'
import {Loader} from 'src/Loader.jsx'
import {HStack} from '@components/Stack'
import {timeSince} from './utils'
import {Avatar} from './Common'
import {Panel} from '@components/Panel'
import {Text} from '@components/Text'
import {Ticker} from '@components/Ticker'
import {motion} from 'framer-motion'

export function ActivityStream({battleId}) {
  const [isFetching, setIsFetching] = useState(false)
  const [activities, setActivities] = useState([])

  useEffect(() => {
    setIsFetching(true)
    getActivityStream(battleId).then((response) => {
      setIsFetching(false)
      if (response.length) {
        response.unshift(response.pop())
      }
      setActivities(response)
    })
  }, [battleId])
  return (
    <>
      {isFetching && (
        <Panel onlyBorder padding="0.5rem">
          <HStack gap="0" justify="center">
            <Loader height="1em" />{' '}
            <Text mono={false} weight={500}>
              Loading activities
            </Text>
          </HStack>
        </Panel>
      )}

      {!isFetching && activities.length > 0 && (
        <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1, transition: {delay: 0.3}}}
          layout
        >
          <Ticker
            items={activities.map((activity) => (
              <div className="activity-item">
                <ActivityItem
                  key={activity.id}
                  timeStamp={activity.timestamp}
                  profile={activity.playerProfile}
                >
                  <Text
                    mono={false}
                    size="0"
                    weight="500"
                    appearance="secondary"
                  >
                    <Text
                      mono={false}
                      size="0"
                      weight="500"
                      appearance="primary"
                    >
                      <Link
                        href={`/player/${
                          activity.playerProfile.username ||
                          activity.playerProfile.id ||
                          activity.playerProfile.uid
                        }`}
                      >
                        <a className="link  link--no-underline">
                          {activity.playerProfile.displayName}
                        </a>
                      </Link>{' '}
                    </Text>
                    {activity.data.userId === activity.data.previousUserId
                      ? 'broke own record on'
                      : 'set a new record on'}{' '}
                    <Link href={`/play/${activity.data.levelId}`}>
                      <a className="link  link--no-underline">
                        #{activity.data.levelId}
                      </a>
                    </Link>{' '}
                    with {activity.data.codeSize} chars (
                    {activity.data.codeSize -
                      activity.data.previousUserCodeSize}
                    ).
                  </Text>
                </ActivityItem>
              </div>
            ))}
          />
        </motion.div>
      )}
    </>
  )
}

export const ActivityItem = ({profile, timeStamp, children}) => {
  return (
    <Panel onlyBorder padding="0.5rem 1rem 0.5rem 0.5rem">
      <HStack align="center" gap="0.5rem">
        <Avatar
          name={profile.displayName}
          size="24"
          url={profile.avatar}
          username={profile.username}
          userId={profile.id || profile.uid}
          useCdnUrl={true}
        />
        <span>{children}</span>
        <Text mono={false} size="0" weight="500" appearance="tertiary">
          {timeSince(timeStamp)} ago
        </Text>
      </HStack>
    </Panel>
  )
}
