import React from 'react'
import {Dialog, DialogOverlay, DialogContent} from '@reach/dialog'
import {AnimatePresence, motion, useReducedMotion} from 'framer-motion'
import {SectionHeader} from 'src/SectionHeader'

const MotionDialogContent = motion(DialogContent)
const MotionDialogOverlay = motion(DialogOverlay)

const spring = {
  type: 'spring',
  damping: 9,
  stiffness: 120,
  duration: 500,
}

function Modal({
  isOpen,
  onRequestClose,
  size = 'big',
  extraClasses,
  header,
  title,
  hideOverlay,
  children,
  ...props
}) {
  const shouldReduceMotion = useReducedMotion()

  return (
    <AnimatePresence>
      {isOpen ? (
        <MotionDialogOverlay
          initial={{opacity: 0}}
          animate={{opacity: 1, transition: {duration: 0.3}}}
          exit={{opacity: 0, transition: {duration: 0.2}}}
          style={{
            background: hideOverlay ? 'transparent' : null,
          }}
          isOpen={isOpen}
          onDismiss={onRequestClose}
          {...props}
        >
          <MotionDialogContent
            className={`modal--${size} ${extraClasses}`}
            transition={shouldReduceMotion ? {duration: 500} : spring}
            initial={{y: shouldReduceMotion ? 0 : -100}}
            animate={{y: 0}}
            exit={{
              opacity: 0,
              y: shouldReduceMotion ? 0 : -200,
              transition: {duration: 0.2},
            }}
            aria-label={title}
          >
            {header ? <SectionHeader>{header}</SectionHeader> : null}
            <div style={{padding: '2rem'}}>{children}</div>
          </MotionDialogContent>
        </MotionDialogOverlay>
      ) : null}
    </AnimatePresence>
  )
}

export {Modal}
