import {KeyCombo} from '@components/KeyCombo'
import {HStack} from '@components/Stack'

export function SectionHeader({
  children,
  sticky,
  keyCombo,
  extraContent,
  meta,
}) {
  const keys = keyCombo && keyCombo.split('+')

  return (
    <div className={`item__header  ${sticky ? 'item__header--sticky' : ''}`}>
      <HStack gap="0.5rem">
        <h3 className="header__title">{children}</h3>
        {extraContent}
        {keyCombo ? <KeyCombo value={keyCombo} /> : null}
      </HStack>
      <div className="header__extra-info">{meta}</div>
    </div>
  )
}
