import {useState} from 'react'

function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue)
  function onChange(e, val) {
    if (val !== undefined) {
      return setValue(val)
    }
    setValue(e.target.type === 'checkbox' ? e.target.checked : e.target.value)
  }
  return {
    input: {
      value,
      onChange,
    },
    value,
    setValue,
  }
}

export {useFormInput}
